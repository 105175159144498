import React from "react"
import Layout from "../components/layout"
import { Link, graphql, StaticQuery } from "gatsby"
import { Grid, ListItem, Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Img from "gatsby-image"
import SEO from "../components/seo"
import website from "../../config/website"

const useStyles = makeStyles(theme => ({
  root: {},
  blogListContainer: {
    display: "flex",
    padding: "5% 10% 1% 10%",
    width: "100%",
    marginTop: "25px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      marginTop: "60px",
    },
  },
  postContainer: {
    backgroundColor: "none",
    margin: "0 3%",
  },
  pageTitle: {
    fontSize: "1.1em",
    fontWeight: "500",
    letterSpacing: "0.1em",
    fontFamily: '"Karla", sans-serif',
  },
  listItem: {
    paddingLeft: "0px",
  },
  blogTitle: {
    textDecoration: "none",
    color: "#565656",
  },
  blogMainImage: {
    width: "330px",
    height: "450px",
    padding: "0px",
    objectFit: "cover",
    "&:hover": {
      transform: "scale(1.02)",
      transition: "transform  .8s ease-out",
      // background: "linear-gradient(to bottom, transparent 0%, black 100%)",
    },
    [theme.breakpoints.down("sm")]: {
      height: "330px",
    },
  },
  imgTextBox: {
    padding: "0% 1%",
    marginLeft: 0,
    color: "rgba(183, 161, 142, 0.19)",
  },
  eventType: {
    fontSize: "0.7em",
    fontFamily: '"Karla", sans-serif',
    textTransform: "uppercase",
    letterSpacing: "0.2em",
    color: "#828282",
    fontWeight: 600,
  },
  imgTextTitle: {
    marginTop: "3px",
    marginBottom: "1px",
    fontSize: "1.15em",
    color: "#615d5d",
    fontFamily: '"Nanum Myeongjo", serif',
    fontWeight: 400,
  },
  venue: {
    fontSize: "0.7em",
    color: "#a5a3a3",
  },
}))

const ContentfulPage = ({ data }) => {
  const classes = useStyles()
  const contentfulList = data.allContentfulMaganBlog.edges

  return (
    <Layout>
      <SEO
        title={`포트폴리오, 프로젝트 | ${website.titleAlt}`}
        desc="매건프로젝트의 웨딩 사례와 그들의 이야기, 기업행사, 이벤트 사례들을 한 눈에 감상하세요."
      />
      <Grid container className={classes.blogListContainer} spacing={2}>
        <Grid item sm={12} xs={12} style={{ marginLeft: "4%" }}>
          <h4 className={classes.pageTitle}>Magan Projects</h4>
        </Grid>
        {contentfulList.map(item => (
          <Grid
            item
            sm={3}
            xs={12}
            className={classes.postContainer}
            key={item.node.id}
          >
            <ListItem className={classes.listItem}>
              <Link
                to={`/contentful/${item.node.slug}`}
                className={classes.blogTitle}
              >
                <Img
                  className={classes.blogMainImage}
                  fluid={item.node.mainImage.fluid}
                  src={item.node.mainImage.file.url}
                  alt={item.node.mainImage.title}
                />
                <Box m={2} className={classes.imgTextBox}>
                  <span className={classes.eventType}>
                    {item.node.projectType}
                  </span>
                  <h4 className={classes.imgTextTitle}>{item.node.title}</h4>
                  <span className={classes.venue}>{item.node.place}</span>
                </Box>
              </Link>
              {/* <p>{item.node.excerpt.slice(0, 53)}</p> */}
            </ListItem>
          </Grid>
        ))}
      </Grid>
    </Layout>
  )
}

const props = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allContentfulMaganBlog(sort: { fields: date, order: DESC }) {
            edges {
              node {
                id
                title
                slug
                projectType
                date
                place
                mainImage {
                  file {
                    url
                  }
                  fluid(maxWidth: 700, quality: 100) {
                    ...GatsbyContentfulFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={data => <ContentfulPage data={data} />}
    />
  )
}

export default ContentfulPage
